import React from "react"
import { graphql } from "gatsby"
import "../styles/main.scss"
import { CardColumns } from "../components/CardColumns"
import ArticleCard, { Article } from "../components/ArticleCard"
import SEO from "../components/seo"
import CategoryLabel from "../components/CategoryLabel"
import { Skeleton } from "../components/layout/Skeleton"
import Section from "../components/course/Section"
import styled from "styled-components"

export default function PageNotFound({
  data,
}: {
  data: {
    allArticle: {
      nodes: Article[]
    }
  }
}) {
  const articles = data.allArticle

  return (
    <>
      <SEO />
      <Skeleton banner={false}>
        <PageNotFoundContainer className={"container"}>
          <Paragraph>
            Wygląda na to że powstało jakieś zamieszanie, i nie jesteśmy w
            stanie znaleźć takiej strony.
          </Paragraph>
          <Paragraph className="mb-6">
            Jesli uważasz że powinniśmy się tym zająć - to daj nam znać pisząć
            na adres{" "}
            <a href={"mailto:kontakt@naszepierwszedziecko.pl"}>
              kontakt@naszepierwszedziecko.pl
            </a>
            .
          </Paragraph>
          <CategoryLabel title={`Nasze ostatnie artykuły:`} />
          <CardColumns>
            {articles.nodes.map((article: Article) => (
              <ArticleCard key={article.id} article={article} />
            ))}
          </CardColumns>
        </PageNotFoundContainer>
      </Skeleton>
    </>
  )
}

const PageNotFoundContainer = styled(Section)`
  max-width: 900px;
`

const Paragraph = styled.p`
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`

export const query = graphql`
  query {
    allArticle(sort: { fields: [published_at], order: DESC }, limit: 3) {
      nodes {
        ...ArticleNodes
      }
    }
  }
`
